<template>
  <div class="wrapper">
    <!-- <Loader /> -->
    <Sidebar />
    <Header />
    <div
      id="content-page"
      class="content-page"
    >
      <transition
        name="router-anim"
        mode="out-in"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOut"
      >
        <router-view />
      </transition>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/core/header/Header'
import Sidebar from '@/components/core/sidebar/Sidebar'
import Footer from '@/components/core/footer/Footer'
// import Loader from '@/components/core/loader/Loader'

export default {
  name: 'MainLayout',
  components: {
    // Loader,
    Header,
    Sidebar,
    Footer
  },
  mounted () {
    document.body.classList = ''
  },
  destroyed () {
    document.body.classList = ''
  }
}
</script>
<style>
@import "../../assets/css/custom.css";
</style>
