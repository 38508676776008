const MUser = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'merchant.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fad fa-chart-tree-map',
    active_icon: 'far fa-chart-tree-map'
  },
  {
    title: 'Reports',
    is_heading: true,
    is_active: false,
    link: {
      name: 'merchant.reports'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'QRPH',
    is_heading: false,
    is_active: false,
    link: {
      name: 'merchant.reports.qrph'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fad fa-qrcode',
    active_icon: 'far fa-qrcode'
  }
  // {
  //   title: 'Dashboard',
  //   is_heading: false,
  //   is_active: false,
  //   link: {
  //     name: 'merchant.dashboard'
  //   },
  //   class_name: '',
  //   is_icon_class: true,
  //   icon: 'fad fa-chart-tree-map',
  //   active_icon: 'far fa-chart-tree-map'
  // },
  // {
  //   title: 'QR Ph',
  //   is_heading: false,
  //   is_active: false,
  //   link: {
  //     name: 'merchant.reports.qrph'
  //   },
  //   class_name: '',
  //   is_icon_class: true,
  //   icon: 'fad fa-qrcode',
  //   active_icon: 'far fa-qrcode'
  // },
  // {
  //   title: 'GCash',
  //   is_heading: false,
  //   is_active: false,
  //   link: {
  //     name: 'merchant.reports.gcash'
  //   },
  //   class_name: '',
  //   is_icon_class: true,
  //   icon: 'fad fa-g',
  //   active_icon: 'far fa-g'
  // },
  // {
  //   title: 'Swift Pay',
  //   is_heading: false,
  //   is_active: false,
  //   link: {
  //     name: 'merchant.reports.swift-pay'
  //   },
  //   class_name: '',
  //   is_icon_class: true,
  //   icon: 'fad fa-globe',
  //   active_icon: 'far fa-globe'
  // }
]

export default MUser
